<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Attendance</div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between mb-5">
      <form class="conFormSubmit conSearchForm mt-5">
        <v-row>
          <v-col cols="12" md="3">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="search.start_date"
                  label="From"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date = parseDate(dateFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search.start_date"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="search.end_date"
                  label="To"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date = parseDate(dateFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search.end_date"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="4" sm="6" cols="12">
            <v-select
              outlined
              v-model="search.user_id"
              :items="users"
              item-text="name"
              item-value="id"
              placeholder="Name"
              hide-details="true"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
              append-icon="mdi-chevron-down"
              clearable
            ></v-select>
          </v-col>
          <!-- <v-col md="3" cols="12">
            <v-text-field
              outlined
              v-model="search.user_name"
              type="text"
              class="form-control"
              id="inputSearch"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-col> -->

          <v-col md="3" cols="12">
            <div class="conOfHeader text-right">
              <div class="btnAdd">
                <v-btn
                  class="btnAddPrimary"
                  @click="getListData(page)"
                  :disabled="isLoadingSearch"
                  :loading="isLoadingSearch"
                >
                  Search
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </form>
    </div>
    <v-row>
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              hide-default-footer
            >
            </v-data-table>
          </div>
        </section>
        <div class="conOfPagination pr-5 pt-5">
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import store from "@/store";
export default {
  data: () => ({
    users: {},
    pagination: {
      current_page: 1,
    },

    isLoadingSearch: false,

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    secDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    menu1: false,
    menu2: false,
    search: {
      start_date: "",
      end_date: "",
      user_id: "",
    },
    searchedData: [],
    headers: [
      { text: "User Name", value: "user_name" },
      { text: "project", value: "project_name" },
      { text: "Data", value: "date" },
    ],

    listData: [],
  }),

  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];

      apiServices
        .get(
          `attendances?per_page=7&page=${page}&start_date=${this.search.start_date}&end_date=${this.search.end_date}&user_id=${this.search.user_id}`
        )
        .then((res) => {
          if (res) {
            store.commit("setCreatedByUser", this.listData.user);
            this.isLoadingSearch = false;
            this.listData = res.data;
            this.pagination = res.meta;
            this.isLoading = false;
            console.log("ListData", this.listData);
          } else this.isLoading = false;
        });
    },
    getUserListData() {
      apiServices.get(`user/users`).then((res) => {
        if (res) {
          this.users = res.data;
          console.log("users", this.users);
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
  },
  created() {
    this.getListData(this.pagination.current_page);
    this.getUserListData();
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import "./_usersattendance.scss";
</style>
